<template>
	<div>
		<kefuright></kefuright>
		<!--轮播图-->
		<home-swiper> </home-swiper>
		<!-- 技术信息汇总 -->
		<div class="jisubox">
			<div class="banxin"> 
				<Con></Con>
				<div class="qiyetop pt60  animate__animated animate__fadeInUp">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">技术信息汇总</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="jibox animate__animated animate__fadeInUp">
					<div class="jileft">
						<div class="jili" v-for="(item,index) in jiList" :key="index"
							:class="{ jactive: jiActive == index }" @mousemove="qieTab(index)">
							<div class="jilileft">
								<div class="jititle">
									{{item.title}}
								</div>
								<div class="jiying">
									{{item.txt}}
								</div>
							</div>
							<img src="../../../assets/img/images/yar_07.png" class="yarlan" alt="" />
							<img src="../../../assets/img/images/yarlan_07.png" class="yarlanact" alt="" />
						</div>
						<div class="rubox" @click="need()">
							<div class="rutxt">
								需求发布入口
							</div>
							<img src="../../../assets/img/images/baisan_07.png" class="rupic" alt="" />
						</div>
					</div>
					<div class="jiyou">
						<div class="jiyouli" v-for="item in tagList" :key="item.id" @click="onNeed(item.id)">
							<div class="sutitle oneline">
								{{item.title}}
							</div>
							<div style="display: flex; align-items: center;flex-wrap: wrap;" class="xunhuana">
								<div class="subox" v-for="items in item.label" :key="items.id" :title="items.name">
									{{items.name}}
								</div>
							</div>
							<div class="xuqiubox">
								<div class="xuone">
									需求预算
								</div>
								<div class="xutwo">
									面议
								</div>
								<img src="../../../assets/img/images/yar_07.png" class="xuthree" alt="" />
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>
		<!-- 服务机构汇总 -->
		<div class="fuwubox">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">服务机构汇总</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="fubox">
					<div class="fuleft">
						<div class="funei">
							<div class="fntitle oneline">
								服务机构汇总
							</div>
							<div class="fntxt">
								达州市科技开发中心、达州市农业科学研究院、达州市玄武岩产业纤维研究院、达州市智信技术转移中心、达州市联创技术转移中心等。
							</div>
							<div class="fnxia">
								<div class="fnfu" @click="organization()">
									进入专题页面
								</div>
								<div class="fnfu" @click="Service()">
									进入服务展示
								</div>
							</div>
						</div>
					</div>

					<div class="fuyou">
						<div class="fuyouli" v-for="item in jgList" :key="item.id" @click="jin(item.id)">
							<div class="futop">
								<img :src="urlImg + item.img" class="fupic" alt="" />
								<div class="fuyun">
									<div class="yuntitle oneline">
										{{item.title}}
									</div>
									<div class="yuntxt twolines" v-html="item.description">
									</div>
								</div>
							</div>
							<div class="fudi">
								<div class="dileft">
									<img src="../../../assets/img/images/ren_11.png" class="renpic" alt="" />
									<div class="rentxt">
										联系人:{{item.legal}}
									</div>
								</div>
								<div class="diyou">
									<img src="../../../assets/img/images/rena_11.png" class="renpic" alt="" />
									<div class="rentxt">
										联系方式:{{
                                                    item.phone.substr(0, 3) +
                                                    "****" +
                                                    item.phone.substr(7)
                                                }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 技术经理人 -->
		<div class="peoplebox">
			<div class="banxin">
				<div class="pt60">
					<div class="gongtop">
						<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
						<div class="zhengbai">技术经理人</div>
						<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
					</div>
				</div>
				<div class="peotitle">
					连接科学技术和企业的中间人，助推科技成果转化、帮助企业转型升级的得力助手。
				</div>
			</div>
		</div>

		<div class="jingbox">
			<div class="banxin">
				<div class="jingbai">
					<div class="peoul">
						<div class="peoli" v-for="item in jsList" :key="item.id" @click="applyXq(item.id)">
							<div class="zhubox">{{item.job}}</div>
							<img src="../../../assets/img/images/head_15.png" class="head" alt="" />
							<div class="zhutitle">
								{{item.title}}
							</div>
							<div class="zhutxt">
								{{item.job_level}}
							</div>
						</div>
					</div>
					<div class="zhuce" @click="apply()"> 注册技术经理人</div>
				</div>
			</div>
		</div>
		<!-- 政策展示 -->
		<div class="cebox">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">政策展示</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="cenei">
					<div class="celeft" @click="member">
						<div class="cetitle">
							政策速递
						</div>
						<div class="cetxt">
							利用大数据产业分析，智能快速的解决企业的产业政策匹配与运用问题。
						</div>
						<div class="cejin" @click="member">
							进入专题页面
						</div>
					</div>
					<div class="cecen">
						<div class="cecentop">
							<div class="ctleft">
								<img src="../../../assets/img/images/zhu_14.png" class="zhupic" alt="" />
								<div class="zctxt">
									政策大数据可视化
								</div>
							</div>
							<div class="jibie">
								<div class="jibieli" v-for="item in cityList" :key="item.index">
									<div class="bietitle">
										{{item.num}}
									</div>
									<div class="bietxt">
										{{item.name}}
									</div>
								</div>
							</div>
						</div>
						<city style="margin-top: -10px;"></city>
					</div>
					<div class="ceright">
						<div class="ceding">政策汇集</div>
						<div class="cesee" @click="zmore">
							查看更多>
						</div>
						<div class="guobox" v-for="(c , index) in newList" :key="c.index" @click="ZCXq(c.id)">
							<img src="../../../assets/img/images/xinb_03.png" v-if="index < 3" class="guopic" alt="" />
							<img src="../../../assets/img/images/xina_03.png" v-else class="guopic" alt="" />
							<div class="guoright">
								<div class="guotitle oneline">
									{{c.title}}
								</div>
								<div class="guotime">
									{{ c.year }}{{ c.created_at  | formatDate}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- 科服快讯 -->
		<div class="xunbg">
			<div class="banxin">
				<div class="pt60">
					<div class="gongtop">
						<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
						<div class="zhengbai">科服快讯</div>
						<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
					</div>
				</div>
				<div class="xunbox">
					<div class="xunli" v-for="item in ExpressList" :key="item.index" @click="newXq(item.id)">
						<img :src="item.image" class="xunpic" alt="" />
						<div class="xunyou">
							<div class="xunnei">
								<div class="xuntitle oneline">
									{{item.title}}
								</div>
								<div class="xuntime">
									{{item.create_time}}
								</div>
								<div class="xuntxt twolines" v-html="item.content">
								</div>
								<img src="../../../assets/img/images/heir_03.png" class="jian" alt="" />
								<img src="../../../assets/img/images/heira_03.png" class="jiana" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import kefuright from '../../../components/common/kefu';
	import FromList from "../../../common/input";
	import Technology from "../../../common/technology"; //技术经理人
	import Con from "./kcon";
	import city from "../../qiyeyun/base/city";
	import {
		formatDate
	} from "../../../common/js/times"; //时间

	import {
		Kefuyun,
		ArticleList,
		achievementsLists,
		DataZC,
	} from "../../../common/js/api";
	import {
		kefu
	} from "../../common/js/api";
	import HomeSwiper from "../../../components/common/Swiper";
	import {
		urlImg
	} from "@/common/js/url";

	export default {
		name: "KefuyunForm",
		filters: {
			formatDate(time) {
				time = time * 1000;
				let date = new Date(time);
				return formatDate(date, "MM -dd");
			},
		},
		components: {
			HomeSwiper,
			FromList,
			Con,
			city,
			Technology,
			kefuright
		},

		data() {
			return {
				// 技术信息总汇
				jiList: [{
						id: 1,
						title: '技术需求展示',
						txt: 'Technical Requirements Presentation'
					},
					{
						id: 2,
						title: '技术出让展示',
						txt: 'Technology transfer display'
					}
				],
				jiActive: 0,
				tagList: [],




				// 技术经理人+
				jsList: [],

				cityList: [],
				// 政策汇集
				newList: [],
				// 科服快讯
				ExpressList: []
			};
		},
		created() {
			this.getDataZC()
			this.getKefuyun();
		},
		mounted() {
			
		},
		methods: {

			// 技术切换
			qieTab(index) {
				this.jiActive = index
				if (this.jiActive == 1) {
					achievementsLists({
						page: 1,
						limits: 6,
					}).then((res) => {
						this.tagList = res.data.data; // 技术信息汇总

						for (let i = 0; i < this.tagList.length; i++) {
							this.tagList[i].title = this.tagList[i].name;
							this.tagList[i].label = [];
							this.tagList[i].label.push({
								name: this.tagList[i].maturity.name
							}, {
								name: this.tagList[i].projecty
							}, {
								name: this.tagList[i].hmethod.name
							});
						}
					});
				} else {
					this.getKefuyun();
				}
			},
			getKefuyun() {
				this.urlImg = urlImg();
				Kefuyun({})
					.then((res) => {
						this.tagList = res.data.demand.slice(0, 6); // 技术信息汇总
						this.jgList = res.data.provider.slice(0, 6); //服务机构汇总
						this.newList = res.data.policy.slice(0, 6); //政策汇集
						this.ExpressList = res.data.news.slice(0, 4); //科服快讯
						this.jsList = res.data.manager.slice(0, 8); //技术经理人
						for (let i = 0; i < this.tagList.length; i++) {
							if (this.tagList[i].label.length > 4) {
								this.tagList[i].label = this.tagList[i].label.slice(0, 4);
							}
						}
					})
					.catch(() => {});
			},
			// 政策汇集详情
			        ZCXq (id) {
			            this.$router.push({
			                path: "/policy",
			                query: {
			                    id: id,
			                },
			            });
			        },
			need() {
				this.$router.push({
					path: '/need'
				})
			},
			// 服务机构汇总  --  进入专题页面
			organization() {
				this.$router.push({
					path: "/organizati",
				});
			},
			// 服务机构汇总  --  进入服务展示
			Service() {
				this.$router.push({
					path: "/service",
				});
			},
			 // 进入服务商主页
				jin(id) {
					this.$router.push({
						path: "/serviceListHome",
						query: {
							id: id,
						},
					});
				},
			// 技术
			// 科服云
			//  技术信息汇总详情
			onNeed(id) {
				if (this.Index == 1) {
					this.$router.push({
						path: "/Achievement_details",
						query: {
							id: id,
						},
					});
				} else {
					this.$router.push({
						path: "/need_details",
						query: {
							id: id,
						},
					});
				}
			},
			// 技术经理人  --  进入专题页面
			apply() {
				this.$router.push({
					path: "/apply",
				});
			},
			// 技术经理人详情
			applyXq(id) {
				this.$router.push({
					path: "/manager",
					query: {
						id: id,
					},
				});
			},

			// 政策速递   --    进入专题页面
			member() {
				let routeData = this.$router.resolve({
					path: "member5/test5-1",
				});
				window.open(routeData.href, "_blank");
			},
			// 政策大数据
			getDataZC() {
				DataZC()
					.then((res) => {
						this.cityList = res.data[0].nums
						console.log('0000000000000000',this.cityList)
					})
					.catch(() => {});
			},
			zmore() {
				this.$router.push({
					path: "/member5",
				});
			},
			// 科服快讯详情
			newXq(id) {
				this.$router.push({
					path: "/Dynamicxq",
					query: {
						id: id,
					},
				});
			},
		},
	};
</script>

<style scoped lang="less">
	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	.pt60 {
		padding-top: 60px;
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.gongtop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 25px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.jisubox {
		padding-bottom: 60px;
		display: flex;
		background-color: #f6f6f6;

		.jibox {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.jileft {
				flex-shrink: 0;
				width: 340px;
				height: 340px;
				border-radius: 6px;
				background-image: linear-gradient(#f9fcfe, #ffffff);
				box-shadow: 0 0 6px #e7ecf2;
				padding: 0 20px;

				.jili {
					display: flex;
					padding: 40px 0 18px;
					cursor: pointer;
					justify-content: space-between;
					border-bottom: 2px solid #bfbfbf;

					.jilileft {
						max-width: 270px;

						.jititle {
							font-size: 24px;
							color: #888888;
							font-weight: bold;
							padding-bottom: 8px;
						}

						.jiying {
							font-size: 12px;
							color: #999999;
						}
					}

					.yarlan {
						width: 25px;
						height: 25px;
					}

					.yarlanact {
						width: 25px;
						height: 25px;
						display: none;
					}
				}

				.jactive {
					border-bottom: 2px solid #0289f4;

					.jilileft {
						.jititle {
							color: #222222;
						}
					}

					.yarlan {
						display: none;
					}

					.yarlanact {
						display: block;
					}
				}

				.rubox {
					cursor: pointer;
					width: 208px;
					height: 40px;
					display: flex;
					align-items: center;
					margin-top: 58px;
					justify-content: center;
					border-radius: 5px;
					background-image: linear-gradient(#57b5ff, #0389f4);

					.rutxt {
						font-size: 18px;
						color: #ffffff;
						padding-right: 20px;
					}

					.rupic {
						width: 7px;
						height: 11px;
					}
				}
			}

			.jiyou {
				display: flex;
				align-items: center;
				flex-grow: 1;
				flex-wrap: wrap;

				.jiyouli:nth-child(1) {
					margin-bottom: 20px;
				}

				.jiyouli:nth-child(2) {
					margin-bottom: 20px;
				}

				.jiyouli:nth-child(3) {
					margin-bottom: 20px;
				}

				.jiyouli {
					width: 330px;
					height: 160px;
					background-image: linear-gradient(#f9fcfe, #ffffff);
					box-shadow: 0 0 6px #e7ecf2;
					padding: 0 20px;
					margin-left: 23px;
					position: relative;
					cursor: pointer;
					transition: all 0.5s;
					.sutitle {
						font-size: 20px;
						color: #222222;
						font-weight: bold;
						padding-bottom: 10px;
						padding-top: 22px;
					}

					.subox {
						padding: 0 5px;
						margin-bottom: 5px;
						height: 25px;
						text-align: center;
						line-height: 25px;
						border-radius: 2px;
						border: 1px solid #adb0b9;
						font-size: 13px;
						color: #adb0b9;
						margin-right: 10px;
					}

					.xuqiubox {
						display: flex;
						align-items: center;
						position: absolute;
						left: 20px;
						bottom: 20px;
						width: 310px;

						.xuone {
							width: 123px;
							height: 37px;
							background-color: #eaf6fe;
							text-align: center;
							line-height: 37px;
							font-size: 16px;
							color: #0289f4;
							margin-right: 10px;
							cursor: pointer;
						}

						.xutwo {
							width: 65px;
							height: 37px;
							background-color: #fdf3eb;
							text-align: center;
							line-height: 37px;
							font-size: 16px;
							color: #ff7200;
							margin-right: 70px;
						}

						.xuthree {
							width: 17px;
							height: 17px;
						}
					}
				}
				.jiyouli:hover{
					background-image: linear-gradient(#0289f4,#0289f4);
					.sutitle {
						color: #ffffff;
					}
					.subox{
						border: 1px solid #ffffff;
						color: #ffffff;
					}
				}
			}
		}
	}

	.fuwubox {
		padding: 60px 0;
		background-color: #ffffff;

		.fubox {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.fuleft {
				width: 340px;
				height: 340px;
				border-radius: 5px;
				background: url('../../../assets/img/images/fubg_03.png') no-repeat center;
				background-size: 340px 340px;
				flex-shrink: 0;

				.funei {
					padding: 38px 25px 0;

					.fntitle {
						font-size: 28px;
						color: #ffffff;
						font-weight: bold;
						padding-bottom: 15px;
					}

					.fntxt {
						font-size: 18px;
						color: #ffffff;
						line-height: 32px;
						padding-bottom: 55px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 4;
						overflow: hidden;
					}

					.fnxia {
						display: flex;
						align-items: center;
						justify-content: space-between;

						

						.fnfu {
							width: 140px;
							height: 35px;
							border: 1px solid #ffffff;
							text-align: center;
							line-height: 35px;
							font-size: 16px;
							color: #ffffff;
							cursor: pointer;
							transition: all 0.5s;
						}
						.fnfu:hover {
							width: 140px;
							height: 35px;
							border: 1px solid #ffffff;
							background-color: #ffffff;
							text-align: center;
							line-height: 35px;
							font-size: 16px;
							color: #0289f4;
							cursor: pointer;
							transform: translateY(-10%);
						}
					}
				}
			}

			.fuyou {
				display: flex;
				align-items: center;
				flex-grow: 1;
				flex-wrap: wrap;

				.fuyouli:nth-child(1) {
					margin-bottom: 20px;
				}

				.fuyouli:nth-child(2) {
					margin-bottom: 20px;
				}

				.fuyouli:nth-child(3) {
					margin-bottom: 20px;
				}

				.fuyouli {
					width: 330px;
					height: 160px;
					background-color: #ffffff;
					box-shadow: 0 0 8px #dddddd;
					margin-left: 23px;
					position: relative;
					cursor: pointer;
					transition: all 0.5s;
					.futop {
						height: 120px;
						border-bottom: 1px solid #eeeeee;
						display: flex;
						align-items: center;
						padding: 0 20px;

						.fupic {
							width: 70px;
							height: 70px;
							border-radius: 5px;
							flex-shrink: 0;
						}

						.fuyun {
							padding-left: 8px;
							flex-grow: 1;

							.yuntitle {
								max-width: 200px;
								font-size: 20px;
								color: #222222;
								font-weight: bold;
								padding-bottom: 10px;
							}

							.yuntxt {
								font-size: 14px;
								color: #888888;
								line-height: 21px;
							}
						}
					}

					.fudi {
						display: flex;
						align-items: center;

						.dileft {
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 140px;
							border-right: 1px solid #eeeeee;

							.renpic {
								width: 14px;
								height: 14px;
							}

							.rentxt {
								font-size: 14px;
								color: #888888;
								padding-left: 5px;
							}
						}

						.diyou {
							height: 40px;
							display: flex;
							align-items: center;
							justify-content: center;
							flex-grow: 1;

							.renpic {
								width: 14px;
								height: 14px;
							}

							.rentxt {
								font-size: 14px;
								color: #888888;
								padding-left: 5px;
							}
						}
					}
				}
				.fuyouli:hover{
					transform: translateY(-5%);
					.futop{
						.yuntitle{
							color: #0289f4;
						}
					}
				}
			}
		}
	}

	.peoplebox {
		width: 100%;
		height: 380px;
		background: url('../../../assets/img/images/shubg_02.png') no-repeat center;
		background-size: 100% 380px;

		.peotitle {
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
	}

	.jingbox {
		display: flex;
		background-color: #f6f6f6;
		padding-bottom: 60px;

		.jingbai {
			padding: 30px 20px 40px;
			background-color: #ffffff;
			border-radius: 7px;
			margin-top: -210px;

			.peoul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding-bottom: 10px;

				.peoli:nth-child(4n) {
					margin-right: 0;
				}

				.peoli {
					width: 325px;
					height: 280px;
					border-radius: 5px;
					box-shadow: 0 0 6px #dddddd;
					margin: 0 20px 20px 0;
					text-align: center;
					position: relative;
					cursor: pointer;
					transition: all 0.5s;
					.zhubox {
						border-radius: 0 30px 30px 0;
						background-color: #0289f4;
						height: 40px;
						line-height: 40px;
						width: 105px;
						font-size: 18px;
						color: #ffffff;
						position: absolute;
						top: 15px;
						left: 0;
					}

					.head {
						width: 130px;
						height: 130px;
						margin: 40px 0 20px;
						border-radius: 50%;
					}

					.zhutitle {
						font-size: 24px;
						color: #222222;
						font-weight: bold;
						padding-bottom: 18px;
					}

					.zhutxt {
						font-size: 16px;
						color: #888888;
					}
				}
				.peoli:hover{
					background-color: #0289f4;
					transform: translateY(-5%);
					.zhubox{
						background-color: #ffffff;
						color: #0289f4;
					}
					.zhutitle{
						color: #ffffff;
					}
					.zhutxt{
						color: #ffffff;
					}
				}
			}

			.zhuce {
				width: 205px;
				height: 40px;
				margin: 0 auto;
				cursor: pointer;
				background-color: #ff4200;
				font-size: 18px;
				color: #ffffff;
				text-align: center;
				border-radius: 5px;
				line-height: 40px;
			}
		}
	}

	.cebox {
		background-color: #f6f6f6;
		padding-bottom: 60px;

		.cenei {
			display: flex;
			align-items: flex-start;
			justify-content: space-between;

			.celeft {
				width: 300px;
				height: 500px;
				background: url('../../../assets/img/images/cebg_05.png') no-repeat center;
				background-size: 300px 500px;
				padding: 0 27px;
				cursor: pointer;
				border-radius: 5px;
				.cetitle {
					font-size: 28px;
					color: #ffffff;
					font-weight: bold;
					padding-bottom: 20px;
					padding-top: 36px;
				}

				.cetxt {
					font-size: 18px;
					color: #ffffff;
					line-height: 35px;
					padding-bottom: 26px;
				}

				.cejin {
					width: 138px;
					height: 34px;
					font-size: 16px;
					color: #ffffff;
					text-align: center;
					line-height: 34px;
					border: 1px solid #ffffff;
					cursor: pointer;
					transition: all 0.5s;
					border-radius: 5px;
				}
				.cejin:hover{
					background-color: #ffffff;
					color: #0289f4;
					transform: translateY(-10%);
				}
			}

			.cecen {
				width: 650px;
				height: 500px;
				background-color: #ffffff;
				flex-shrink: 0;

				.cecentop {
					display: flex;
					align-items: flex-start;
					padding: 25px 35px 0 20px;
					justify-content: space-between;

					.ctleft {
						display: flex;
						align-items: center;

						.zhupic {
							width: 24px;
							height: 20px;
						}

						.zctxt {
							font-size: 24px;
							color: #222222;
							padding-left: 7px;
						}
					}

					.jibie {
						width: 335px;
						height: 65px;
						border: 1px solid #e5e5e5;
						display: flex;
						align-items: center;

						.jibieli {
							width: 25%;
							text-align: center;

							.bietitle {
								font-size: 24px;
								color: #0289f4;
								font-weight: bold;
								padding-bottom: 5px;
							}

							.cheng {
								color: #ff7200;
							}

							.bietxt {
								font-size: 14px;
								color: #222222;
							}
						}
					}
				}
			}

			.ceright {
				width: 410px;
				height: 500px;
				padding: 0 20px;
				background-color: #ffffff;
				position: relative;

				.ceding {
					width: 170px;
					height: 45px;
					background: url('../../../assets/img/images/lanbg_11.png') no-repeat center;
					top: 20px;
					left: -14px;
					position: absolute;
					background-size: cover;
					text-align: center;
					line-height: 40px;
					color: #ffffff;
					font-size: 22px;
					font-weight: bold;
				}

				.cesee {
					font-size: 16px;
					color: #888888;
					text-align: right;
					padding: 35px 0 30px;
					cursor: pointer;
				}

				.guobox {
					display: flex;
					align-items: flex-start;
					padding-bottom: 17px;
					cursor: pointer;

					.guopic {
						width: 40px;
						height: 35px;
						flex-shrink: 0;
					}

					.guoright {
						padding-left: 10px;
						flex-shrink: 0;
						padding-top: 8px;

						.guotitle {
							font-size: 18px;
							color: #000000;
							padding-bottom: 14px;
							max-width: 320px;
						}

						.guotime {
							font-size: 14px;
							color: #888888;
						}
					}
				}
				.guobox:hover{
					.guoright {
						.guotitle {
							color: #0289f4;
						}
					}
				}
			}
		}
	}

	.xunbg {
		width: 100%;
		height: 620px;
		background: url('../../../assets/img/images/kebg_08.png') no-repeat center;
		background-size: 100% 620px;

		.xunbox {
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			.xunli {
				width: 50%;
				display: flex;
				align-items: center;
				height: 220px;
				cursor: pointer;

				.xunpic {
					width: 50%;
					height: 100%;
				}

				.xunyou {
					transition: all 0.5s;
					background-color: #ffffff;
					width: 50%;
					height: 100%;
					position: relative;
					border-bottom: 1px solid #eeeeee;

					.xunnei {
						padding: 27px 15px 0;

						.xuntitle {
							font-size: 22px;
							color: #222222;
							font-weight: bold;
							padding-bottom: 15px;
						}

						.xuntime {
							font-size: 14px;
							color: #0289f4;
							padding-bottom: 20px;
						}

						.xuntxt {
							font-size: 16px;
							color: #222222;
							line-height: 28px;
						}
					}

					.jian {
						width: 25px;
						height: 8px;
						position: absolute;
						bottom: 20px;
						right: 20px;
						cursor: pointer;
					}

					.jiana {
						display: none;
						width: 25px;
						height: 8px;
						position: absolute;
						bottom: 20px;
						right: 20px;
						cursor: pointer;
					}
				}

				.xunyou::before {
					transition: all 0.5s;
					content: "";
					position: absolute;
					left: -20px;
					top: 50%;
					margin-top: -1rem;
					border-top: solid 0.875rem transparent;
					border-bottom: solid 0.875rem transparent;
					border-right: #fff 1rem solid;
				}
			}

			.xunli:hover {
				.xunyou {
					background-color: #0289f4;

					.xunnei {
						.xuntitle {
							color: #ffffff;
						}

						.xuntime {
							color: #ffffff;
						}

						.xuntxt {
							color: #ffffff;
						}
					}

					.jian {
						display: none;
					}

					.jiana {
						display: block;
					}
				}

				.xunyou::before {
					content: "";
					position: absolute;
					left: -20px;
					top: 50%;
					margin-top: -1rem;
					border-top: solid 0.875rem transparent;
					border-bottom: solid 0.875rem transparent;
					border-right: #0289f4 1rem solid;
				}
			}
		}
	}
	
	
	@media screen and (max-width: 1200px) {
		#app{
			overflow: hidden !important;  
		}
		.banxin{
			width: 98%;
		}
		.cebox .cenei{
			overflow: hidden;
		}
		.qiyetop .zhengtitle{
			font-size: 3rem;
		}
		.jisubox .jibox{
			flex-wrap: wrap;
		}
		.jisubox .jibox .jileft{
			display: flex;
			width: 100%;
			height: auto;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
			margin-bottom: 1.5rem;
		}
		.jisubox .jibox .jileft .rubox{
			margin: 2rem auto;
			width: 12rem;
			height: 3.5rem;
			line-height: 3.5rem;
			font-size: 1.5rem;
		}
		.jisubox .jibox .jileft .rubox .rutxt{
			font-size: 1.5rem;
		}
		.jisubox .jibox .jileft .jili .jilileft{
			max-width: 100%;
		}
		.jisubox .jibox .jileft .jili .jilileft .jititle{
			font-size: 1.6rem;
		}
		.jisubox .jibox .jileft .jili .jilileft .jiying{
			font-size: 1.2rem;
		}
		.jisubox .jibox .jileft .rubox .rupic{
			width: 1rem;
			height: 1.5rem;
		}
		.jisubox .jibox .jiyou{
			justify-content: space-between;
		}
		.jisubox .jibox .jiyou .jiyouli{
			width: 49%;
			margin: 0 0 1rem;
			height: 12rem;
		}
		
		.jisubox .jibox .jiyou .jiyouli .sutitle{
			font-size: 1.8rem;
		}
		.jisubox .jibox .jiyou .jiyouli .xuqiubox{
			width: 100%;
		}
		.jisubox .jibox .jiyou .jiyouli .xuqiubox .xuone{
			width: 10rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.4rem;
		}
		.jisubox .jibox .jiyou .jiyouli .xuqiubox .xutwo{
			width: 7rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.4rem;
			margin-right: 2.5rem;
		}
		.jisubox .jibox .jiyou .jiyouli .xuqiubox .xuthree{
			width: 1.5rem;
			height: 1.5rem;
		}
		.fuwubox .fubox .fuleft{
			width: 100%;
			height: 25rem;
			background-size: 100% 25rem;
		}
		.fuwubox .fubox{
			flex-wrap: wrap;
		}
		.fuwubox .fubox .fuleft .funei .fntitle{
			font-size: 2rem;
		}
		.fuwubox .fubox .fuleft .funei .fntxt{
			width: 60%;
			font-size: 1.5rem;
			line-height: 2.5rem;
		}
		.xunhuana{
			height: 2.5rem;
			overflow: hidden;
		}
		.jisubox .jibox .jiyou .jiyouli .subox{
			width: 9.8rem;
			height: 2.5rem;
			line-height: 2.5rem;
			font-size: 1.4rem;
		}
		.fuwubox .fubox .fuleft .funei .fnxia{
			justify-content: flex-start;
		}
		.fuwubox .fubox .fuleft .funei .fnxia .fnli{
			width: 40%;
			line-height: 3.5rem;
			font-size: 1.8rem;
			margin-right: 2rem;
			height: 3.5rem;
		}
		.fuwubox .fubox .fuleft .funei .fnxia .fnfu{
			width: 40%;
			line-height: 3.5rem;
			font-size: 1.8rem;
			height: 3.5rem;
		}
		.fuwubox .fubox .fuyou{
			justify-content: space-between;
			padding-top: 2rem;
		}
		.fuwubox .fubox .fuyou .fuyouli{
			width: 100%;
			margin: 0 0 1rem;
			height: 16rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .futop{
			padding: 1rem;
			height: auto;
		}
		.fuwubox .fubox .fuyou .fuyouli .futop .fupic{
			width: 10rem;
			height: 10rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .futop .fuyun{
			padding-left: 1.5rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .futop .fuyun .yuntitle{
			font-size: 1.9rem;
			max-width: 80%;
		}
		.fuwubox .fubox .fuyou .fuyouli .futop .fuyun .yuntxt{
			font-size: 1.6rem;
			line-height: 2.5rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .dileft{
			width: auto;
			height: auto;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .dileft .renpic{
			width: 1.6rem;
			height: 1.6rem;
			margin-left: 1rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .dileft .rentxt{
			font-size: 1.6rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .diyou{
			height: auto;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .diyou .renpic{
			width: 1.6rem;
			height: 1.6rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi .diyou .rentxt{
			font-size: 1.6rem;
		}
		.fuwubox .fubox .fuyou .fuyouli .fudi{
			padding-top: 1rem;
		}
		.gongtop .zhengbai{
			font-size: 3rem;
		}
		.peoplebox{
			height: 24rem;
			background-size: 100% 24rem;
		}
		.peoplebox .peotitle{
			font-size: 1.8rem;
		}
		.jingbox .jingbai .peoul{
			justify-content: space-between;
		}
		.jingbox .jingbai .peoul .peoli{
			margin: 0 0 1rem;
			width: 49%;
			height: 16rem;
		}
		.jingbox .jingbai .peoul .peoli .head{
			width: 8rem;
			height: 8rem;
		}
		.jingbox .jingbai .peoul .peoli .zhubox{
			width: 8rem;
			height: 2.5rem;
			line-height: 2.5rem;
			font-size: 1.3rem;
		}
		.jingbox .jingbai .peoul .peoli .zhutitle{
			font-size: 1.7rem;
		}
		.jingbox .jingbai .peoul .peoli .zhutxt{
			font-size: 1.4rem;
		}
		.jingbox .jingbai .zhuce{
			width: 15rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.6rem;
		}
		.cebox .cenei{
			flex-wrap: wrap;
		}
		
		.cebox .cenei .celeft .cetitle{
			font-size: 2rem;
		}
		.cebox .cenei .celeft .cetxt{
			width: 100%;
			font-size: 1.5rem;
			line-height: 2.5rem;
		}
		.cebox .cenei .celeft .cejin{
			width: 10rem;
			height: 3rem;
			line-height: 3rem;
			font-size: 1.4rem;
		}
		.cebox .cenei .ceright{
			width: 100%;
			height: auto;
		}
		.cebox .cenei .ceright .guobox .guoright .guotitle{
			max-width: 64%;
			font-size: 1.8rem;
		}
		.cebox .cenei .ceright .guobox .guopic{
			width: 3rem;
			height: 2.7rem;
		}
		.cebox .cenei .ceright .guobox .guoright .guotime{
			font-size: 1.4rem;
		}
		.cebox .cenei .ceright .ceding[data-v-5af70f0f]{
			width: 10rem;
			height: 3.5rem;
			background-size: 10rem 3.5rem;
			line-height: 3rem;
			font-size: 1.4rem;
		}
		.cebox .cenei .ceright .cesee{
			font-size: 1.4rem;
		}
		.xunbg .xunbox .xunli{
			width: 100%;
		}
		.xunbg{
			height: 63rem;
			background-size: 100% 63rem;
		}
		.xunbg .xunbox .xunli .xunyou .xunnei .xuntitle{
			font-size: 1.8rem;
		}
		.xunbg .xunbox .xunli .xunyou .xunnei .xuntime{
			font-size: 1.3rem;
		}
		.xunbg .xunbox .xunli .xunyou .xunnei .xuntxt{
			font-size: 1.4rem;
			line-height: 1.7rem;
		}
	}
</style>