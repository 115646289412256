<template>
  <!-- 技术经理人 -->
  <div>
       <div class="pea-title">
          <div class="one-content">
            <div class="copy-title">
              <div class="copy-con">
                <span></span>
                <h3>技术经理人</h3>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <el-row>
            <div class="left Summary technology">
              <div class="sum">
                <h3 class="sum_xq">技术经理人</h3>
                <p>
                  连接科学技术和企业的中间人，助推科技成果转化、帮助企业转型升级的得力助手。
                </p>
                <button class="jr jr1" @click="apply()">注册技术经理人</button>
              </div>
            </div>

            <el-card class="box-card Technician right">
              <div class="envi-content">
                <ul>
                  <li
                    class="Service-item"
                    v-for="i in jsList"
                    :key="i.id"
                    @click="applyXq(i.id)"
                  >
                    <div class="envi-type">
                      <!-- <div class="envi-img">
                        <img src="../../../assets/img/kefuyun/jsjlr.jpg" />
                      </div> -->

                      <div class="each-list-top">
                        <div class="expert-img">
                          <i class="el-icon-s-custom"></i>
                          <!-- <img
                            alt=""
                            data-src="undefined"
                            src="../../../assets/img/kefuyun/jsjlr.jpg"
                            lazy="error"
                          /> -->
                        </div>
                        <div class="expert-text">
                          <h3 class="expert-name">{{ i.title }}</h3>
                          <span class="expert-btn"
                            ><i class="el-icon-menu"></i> {{ i.job }}
                          </span>
                        </div>
                      </div>
                      <div class="envi-text">
                        <p>
                          {{ i.job_level }}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </el-card>
          </el-row>
        </div>
  </div>
</template>
<script>
import { Kefuyun } from "./js/api";
import { urlImg } from "./js/url";
export default {
  name: "Technology",

  data() {
    return {
      jsList: [],
    };
  },
  created() {
    this.getKefuyun();
  },
  methods: {
     // 科服云
    getKefuyun() {
      this.urlImg = urlImg();
      Kefuyun({})
        .then((res) => {
           this.jsList = res.data.manager.slice(0, 8); //技术经理人
         })
        .catch(() => {});
    },
   // 技术经理人  --  进入专题页面
    apply() {
      this.$router.push({
        path: "/apply",
      });
    },
    // 技术经理人详情
    applyXq(id) {
      this.$router.push({
        path: "/manager",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.Summary {
    height: 380px;
    width: 200px;

}

.Summary .sum {
    padding: 46px 23px 0 23px;
    color: rgba(255, 255, 255, 100);
    text-align: left;
}

.Summary .sum_xq {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.Summary .sum p {
    font-size: 14px;
    line-height: 25px;
    font-family: SourceHanSansSC-regular;
}

.Summary .jr {
    cursor: pointer;
    margin-top: 15px;
    width: 128px;
    height: 30px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 100);
    color: rgba(56, 146, 235, 100);
    font-size: 14px;
    text-align: center;
    font-family: Microsoft Yahei;
    border: 1px solid rgba(255, 255, 255, 100);
}

.Technician {
    width: 976px;
    height: 380px;
    padding: 20px;
}

.technology {
    background: url(../assets/img/kefuyun/js.png) no-repeat center;
    background-size: 100% 100%;
}

.Summary .sum .jr1 {
    color: rgba(238, 119, 45, 100);
}

</style>