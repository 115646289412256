<template>
    <div>
         <con-tab @TwoClick="TwoClick"></con-tab>
        <!--科服云-->
        <Kefuyun-form ></Kefuyun-form>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import KefuyunForm from "./base/KefuyunForm";
    // import Qs from 'qs'
    export default {
        name: "Kefuyun",
        data(){
            return {
                
            }
        },
        created(){
            
        },
        methods:{
             TwoClick(item){
                //console.log(item);
                this.ConList = item
            },
        },
        components:{
            ConTab,
            KefuyunForm
        }
    }
</script>

<style scoped>

</style>
