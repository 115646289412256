<template>
	<!-- 标题 -->
	<div class="banxin">
		<div class="kf_con">
			<div class=" xinxi" v-for="(item ,index) in aalist" :key="index">
				<img src="../../../assets/img/images/numa_03.png" v-if="index ===0" class="xinpic" alt="">
				<img src="../../../assets/img/images/numb_03.png" v-if="index ===1" class="xinpic" alt="">
				<img src="../../../assets/img/images/numc_03.png" v-if="index ===2" class="xinpic" alt="">
				<img src="../../../assets/img/images/numd_03.png" v-if="index ===3" class="xinpic" alt="">

				<router-link :to="item.url" v-if="item.url != undefined" target="_blank">
					<div class="xinyou" @click="item.url">
						<p class="bule">
							<number-roller :num="item.num"></number-roller>
						</p>
						<p>{{ item.name }}</p>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Data
	} from "../../../common/js/api";
	export default {
		components: {
			NumberRoller: () => import("@/common/number-roller.vue"),
		},
		data() {
			return {
				aalist: [],
				kf_conList: [],
			};
		},
		created() {
			this.getNums();
		},
		methods: {
			// 数据
			getNums() {
				Data()
					.then((res) => {
						console.log(res)
						var number = res.data[0].nums.map(item => {
							item.num *= 1
							return item
						})
						this.aalist = number
						this.aalist[0].url = '/dynamicS'
						this.aalist[1].url = '/organizati'
						this.aalist[2].url = '/humancloud'
						this.aalist[3].url = '/member5'
					})
					.catch(() => {});
			},
		},
	};
</script>

<style lang="less" scoped>
	.banxin {
		width: 1400px;
	}

	/* 标题 */
	.kf_con {
		height: 190px;
		box-sizing: border-box;
		margin-top: -65px;
		background-color: #ffffff;
		border-radius: 5px;
		display: flex;
		align-items: center;
	}

	.xinxi {
		height: 140px;
		width: 25%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right: 1px solid #e5e5e5;
		cursor: pointer;
	}

	.xinxi:last-child {
		border: none,
	}

	.xinpic {
		width: 62px;
		height: 59px;
	}

	.xinyou {
		padding-left: 20px;
	}

	.kf_con .col {
		position: relative;
	}

	.kf_con p {
		font-size: 18px;
		text-align: left;
		font-family: SourceHanSansSC-regular;
		color: #333333;
		text-align: left;
		padding-left: 5px;
	}

	.kf_con .bule {
		font-size: 42px;
		font-style: italic;
		color: #ff4200;
		margin-bottom: 10px;
		font-weight: bold;
		text-align: left;
	}

	/*  */
	@media screen and (max-width: 1200px) {
		.kf_con {
			margin-top: 0;
		}

		.banxin {
			width: 100% !important;
		}

		.kf_con p {
			font-size: 1.3rem;
		}
	}
</style>